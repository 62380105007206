<template>
  <v-row>
    <v-col
      cols="12"
      sm="8"
    >
      <v-sheet
        min-height="70vh"
        rounded="lg"
        class="placa-vendese"
        id="placa-vendese"
      >
        <div class="placa-vendese" style="text-align: center;">
          <h1 style="font-size: 25vh;">VENDE-SE</h1>
          <h3 style="font-size: 5vh;">PARA MAIS INFORMAÇÕES ENTRE EM CONTATO</h3>
          <h2 style="font-size: 16vh;">{{ phone }}</h2>
        </div>
      </v-sheet>
      <v-sheet
        class="mt-3"
        min-height="30vh"
        rounded="lg"
      >
       <amp-ad width="100vw" height="320"
            type="adsense"
            data-ad-client="ca-pub-6212809217859117"
            data-ad-slot="7703166980"
            data-auto-format="rspv"
            data-full-width="">
          <div overflow=""></div>
        </amp-ad>
      </v-sheet>
    </v-col>

    <v-col
      cols="12"
      sm="4"
    >
      <v-sheet
        class="mb-3 pa-2"
        rounded="lg"
      >
        <v-btn
          @click="imprimir()"
          style="width: 28%; float: right; margin-left: 2%; height: 56px"
          depressed
          class="btn-imprimir"
          color="primary"
        >
          IMPRIMIR
        </v-btn>
        <v-text-field
          v-model="phone"
          v-mask="maskTel()"
          label="Digite aqui seu número de telefone para imprimir"
          hide-details
          outlined
          style="width: 70%"
        ></v-text-field>
      </v-sheet>
      <v-sheet
        class="mb-3 pa-3"
        rounded="lg"
        min-height="200"
      >
        Este site serve como um facilitador para imprimir placas de forma gratuita. Nele você pode imprimir placas de "Vende-se", "Aluga-se" e "Máscara obrigatória". Você pode editar o número de telefone que ficará nas placas no campo acima. Não é preciso fazer o download de nenhum arquivo e nem editar nada, apenas informar o número e clicar no botão "IMPRIMIR" que o site abrirá uma janela com a impressão já pronta, basta selecionar a impressora. Futuramente vamos adicionar mais placas.
      </v-sheet>
      <v-sheet
        class="mb-3"
        rounded="lg"
        min-height="268"
      >
      </v-sheet>
      <v-sheet
        class="mb-3"
        rounded="lg"
        min-height="268"
      >
        <!--  -->
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Vendese',
  metaInfo: {
    htmlAttrs: {
      lang: 'pt-BR',
    },
    title: 'Imprimir placa online grátis - Vende-se',
    meta: [
      { name: 'descrition', content: 'Imprimir placa online grátis de vende-se ou aluga-se com o número e placa de mascara obrigatória. Placa para imprimir' },
      { name: 'keywords', content: 'Imprimir,placa,online,grátis,vende-se,aluga-se,com o número,placa de mascara obrigatória, para imprimir' }
    ],
  },
  data: () => ({
    phone: null
  }),
  methods: {
    maskTel() {
      if(this.phone) {
        return this.phone.length == 15 ? '(##) #####-####' : '(##) ####-#####'
      } else {
        return '(##) #####-####'
      }
    },
    imprimir() {
      this.$htmlToPaper("placa-vendese");
    }
  }
};
</script>

<style lang="scss">
@media print{@page {size: landscape}}

.btn-imprimir {
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
	0% {
		// transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.9);
	}
	
	70% {
		//transform: scale(1);
		box-shadow: 0 0 0 10px rgba(25, 118, 210, 0);
	}
	
	100% {
		// transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(25, 118, 210, 0);
	}
}

@media screen and (max-width: 720px) {
  .v-tabs-bar {
    margin-left: 20px;
  }
  .title-page {
    h1 {
      font-size: 8px !important;
    }
  }
  .placa-vendese {
    min-height: 32vh !important;

    h1 {
      font-size: 11vh !important;
    }

    h3 {
      font-size: 2vh !important;
    }

    h2 {
      font-size: 6vh !important;
    }
  }
}
</style>